<template lang="pug">
//-- ========== Left Sidebar Start ==========
.left-side-menu
  //-- LOGO
  .logo-box
    router-link.logo.logo-dark.text-center(to="/")
      span.logo-sm
        img(src="@/assets/images/new-use/sm-logo.jpeg", alt="", height="24")
        //-- <span class="logo-lg-text-light">Minton</span>
      span.logo-lg
        img(src="@/assets/images/logo-dark.png", alt="", height="20")
        //-- <span class="logo-lg-text-light">M</span>
    router-link.logo.logo-light.text-center(to="/")
      span.logo-sm
        img(src="@/assets/images/new-use/sm-logo.jpeg", alt="", height="24")
      span.logo-lg
        img(src="@/assets/images/logo.png", alt="", height="36")
  simplebar.h-100(data-simplebar="")
    //-- - Sidemenu
    #sidebar-menu(v-if="$root.user.id")
      //-- Left Menu Start
      ul#side-menu.list-unstyled
        template(
          v-for="item in menuItems"
        )
          li.menu-title(
            :permissions="item.permissions", 
            v-if="($root.user.permission.indexOf(item.permissions) > -1) && ( item.isTitle)", :key="item.id"
          ) {{ $t(item.label) }}
          li(
            :permissions="item.permissions", 
            v-if="($root.user.permission.indexOf(item.permissions) > -1) && (!item.isTitle && !item.isLayout)", :key="item.id"
          )
            a(
              v-if="hasItems(item)",
              href="javascript:void(0);",
              @click="item.isMenuCollapsed = !item.isMenuCollapsed",
              :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            )
              i(:class="`${item.icon}`", v-if="item.icon")
              span {{ $t(item.label) }}
              span.menu-arrow(v-if="!item.badge")
              span(
                :class="`badge badge-pill badge-${item.badge.variant} float-right`",
                v-if="item.badge"
              ) {{ $t(item.badge.text) }}
            router-link.side-nav-link-ref(
              :to="item.link",
              v-if="!hasItems(item)"
            )
              i(:class="`${item.icon}`", v-if="item.icon")
              span {{ $t(item.label) }}
              span(
                :class="`badge badge-pill badge-${item.badge.variant} float-right`",
                v-if="item.badge"
              ) {{ $t(item.badge.text) }}
            #sidebarTasks.collapse(:class="{ show: item.isMenuCollapsed }")
              ul.sub-menu.nav-second-level(
                v-if="hasItems(item)",
                aria-expanded="false"
              )
                li(
                  v-for="(subitem, index) of item.subItems", :key="index"
                  v-if="$root.user.permission.indexOf(subitem.permissions) > -1"
                )
                  router-link.side-nav-link-ref(
                    :to="subitem.link",
                    v-if="!hasItems(subitem)"
                  ) {{ $t(subitem.label) }}
                  a.side-nav-link-a-ref.has-arrow(
                    v-if="hasItems(subitem)",
                    @click="subitem.isMenuCollapsed = !subitem.isMenuCollapsed",
                    href="javascript:void(0);"
                  )
                    | {{ $t(subitem.label) }}
                    span.menu-arrow
                  .collapse(:class="{ show: subitem.isMenuCollapsed }")
                    ul.sub-menu(
                      v-if="hasItems(subitem)",
                      aria-expanded="false"
                    )
                      li(
                        v-for="(subSubitem, index) of subitem.subItems",
                        :key="index"
                      )
                        router-link.side-nav-link-ref(:to="subSubitem.link") {{ $t(subSubitem.label) }}
    //-- End Sidebar
    .clearfix
  //-- Sidebar -left
//-- Left Sidebar End
</template>

<script>
import {
  mapState
} from "vuex";
import {
  menuItems
} from "./menu";

/**
 * Sidebar component
 */
export default {
  data () {
    return {
      menuItems: menuItems,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    menu: {
      type: String,
      required: true,
    },
    topbar: {
      type: String,
      required: true,
    }
  },
  computed: mapState(["layout"]),
  watch: {
    type: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              // document.body.setAttribute("data-sidebar-color", "dark");
              break;
            case "light":
              // document.body.setAttribute("data-sidebar-color", "light");
              break;
            case "brand":
              // document.body.setAttribute("data-sidebar-color", "brand");
              break;
            case "gradient":
              // document.body.setAttribute("data-sidebar-color", "gradient");
              break;
            default:
              // document.body.setAttribute("data-sidebar-color", "light");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              // document.body.setAttribute("data-layout-width", "boxed");
              // document.body.setAttribute("data-sidebar-size", "condensed");
              break;
            case "fluid":
              // document.body.setAttribute("data-layout-width", "fluid");
              // document.body.removeAttribute("data-sidebar-size");
              // document.body.removeAttribute("data-layout-width");
              break;
            default:
              // document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
    size: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "default":
              // document.body.setAttribute("data-sidebar-size", "default");
              break;
            case "condensed":
              // document.body.setAttribute("data-sidebar-size", "condensed");
              break;
            case "compact":
              // document.body.setAttribute("data-sidebar-size", "compact");
              break;
            default:
              // document.body.setAttribute("data-sidebar-size", "default");
              break;
          }
        }
      },
    },
    menu: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "fixed":
              // document.body.setAttribute("data-layout-menu-position", "fixed");
              break;
            case "scrollable":
              // document.body.setAttribute(
              //   "data-layout-menu-position",
              //     "scrollable"
              //                 );
              break;
            default:
              // document.body.setAttribute("data-layout-menu-position", "fixed");
              break;
          }
        }
      },
    },
    topbar: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              // document.body.setAttribute("data-topbar-color", "dark");
              break;
            case "light":
              // document.body.setAttribute("data-topbar-color", "light");
              break;
            default:
              // document.body.setAttribute("data-topbar-color", "dark");
              break;
          }
        }
      },
    },
  },
  mounted: function () {
    this._activateMenuDropdown();
    this.$router.afterEach((routeTo, routeFrom) => {
      this._activateMenuDropdown();
    });
  },
  created () {
    console.log('=> 管理員所擁有的權限清單: ', this.$root.user.permission)
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems (item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    _activateMenuDropdown () {
      const resetParent = (el) => {
        el.classList.remove("active");
        var parent = el.parentElement;
        if (parent) {
          parent.classList.remove("menuitem-active");
          const parent2 = parent.parentElement;
          if (parent2) {
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.remove("show");
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.remove("menuitem-active");
              }
            }
          }
        }
      };
      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      const paths = [];
      for (let i = 0; i < links.length; i++) {
        // reset menu
        resetParent(links[i]);
      }
      for (var i = 0; i < links.length; i++) {
        paths.push(links[i]["pathname"]);
      }
      var itemIndex = paths.indexOf(window.location.pathname);
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf("/");
        const item = window.location.pathname.substr(0, strIndex).toString();
        matchingMenuItem = links[paths.indexOf(item)];
      } else {
        matchingMenuItem = links[itemIndex];
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("menuitem-active");
          const parent2 = parent.parentElement;
          if (parent2) {
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("show");
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.add("menuitem-active");
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.logo-sm img
  border-radius: 50%

body
  .left-side-menu
    background: #0073b8
    .logo-box
      background: #0073b8
    .nav-second-level li a
      color: #ffffffb3
    #sidebar-menu > ul > li > a
      color: #ffffffb3
</style>
