export const menuItems = [
    {
        id: 43,
        permissions: "MENU_CONTENT",
        label: "數據分析",
        icon: "ri-line-chart-fill",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 44,
                permissions: "MENU_CONTENT",
                label: 'dashboard',
                link: '/dashboard'
            },
            {
                id: 45,
                permissions: "MENU_CONTENT",
                label: '新聞數據查詢',
                link: '/news-analysis'
            },
            // {
            //     id: 46,
            //     permissions: "MENU_LAYOUT",
            //     label: '中心數據查詢',
            //     link: '/center-analysis'
            // },
            // {
            //     id: 47,
            //     permissions: "MENU_LAYOUT",
            //     label: '外稿資料',
            //     link: '/external-content-analysis'
            // },
            // {
            //     id: 48,
            //     permissions: "MENU_LAYOUT",
            //     label: '影音節目數據查詢',
            //     link: '/media-program-analysis'
            // },
            // {
            //     id: 49,
            //     permissions: "MENU_LAYOUT",
            //     label: '流量/訪客',
            //     link: '/traffic-analysis'
            // }
        ]
    },
    {
        id: 1,
        permissions: 'MENU_LAYOUT',
        label: "行銷版位管理",
        icon: "fe-layout",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 2,
                permissions: "MENU_LAYOUT_HOME",
                label: '大首頁',
                link: '/'
            },
            {
                id: 3,
                permissions: "MENU_LAYOUT_TYPE_PAGE",
                label: '分類頁管理',
                link: '/types-page'
            },
            {
                id: 4,
                permissions: "MENU_LAYOUT_ANNOUNCEMENT",
                label: '公告',
                link: '/announcement'
            },
            {
                id: 5,
                permissions: "MENU_LAYOUT_MARQUEE",
                label: '快訊跑馬燈',
                link: '/marquee'
            },
            {
                id: 6,
                permissions: "MENU_LAYOUT_CATEGORY",
                label: '分類文字設定',
                link: '/category'
            },
            {
                id: 7,
                permissions: "MENU_LAYOUT_PLACEMENT",
                label: '分頁新聞版位設定',
                link: '/placement'
            },
        ]
    },
    {
        id: 8,
        permissions: "MENU_CONTENT",
        label: "上稿管理",
        icon: "fe-edit",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 9,
                permissions: "MENU_CONTENT_ARTICLES",
                label: '新聞/文章編輯',
                link: '/articles'
            },
            {
                id: 47,
                permissions: "MENU_CONTENT_ARTICLES",
                label: '導購連結設定',
                link: '/guide-links'
            },
            {
                id: 10,
                permissions: "MENU_CONTENT_TAGS",
                label: 'tag設定',
                link: '/tagsettings'
            },
            {
                id: 11,
                permissions: "MENU_CONTENT_MEDIA_LIBRARY",
                label: '影音庫',
                link: '/videolibrary'
            },
            {
                id: 12,
                permissions: "MENU_CONTENT_INTERNAL_ANNOUNCEMENTS",
                label: '內部公告編輯',
                link: '/internal-announcements'

            },
            {
                id: 13,
                permissions: "MENU_CONTENT_RSS_SETTING",
                label: 'RSS設定',
                link: '/rsssettings'
            }
        ]
    },
    {
        id: 14,
        permissions: "MENU_LIVE_STREAM",
        label: "直播管理",
        icon: "fe-play-circle",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 15,
                permissions: "MENU_LIVE_STREAM_HOME",
                label: '專區首頁編輯',
                link: '/livestream'
            },
            {
                id: 16,
                permissions: "MENU_LIVE_STREAM_SETTING",
                label: '直播設定',
                link: '/setting'
            },
            {
                id: 17,
                permissions: "MENU_LIVE_STREAM_PROGRAMS",
                label: '節目表',
                link: '/program-schedule'
            }
        ]
    },
    {
        id: 18,
        permissions: "MENU_ELECTIONS",
        label: "選舉頁面專區",
        icon: "ri-dashboard-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 19,
                permissions: "MENU_ELECTION_SETTING",
                label: '設定列表',
                link: '/election'
            },
            {
                id: 20,
                permissions: "MENU_ELECTION_SETTING",
                label: '政黨列表',
                link: '/election-partys'
            }
            // {
            //     id: 20,
            //     permissions: "MENU_ELECTION_ACCOUNT",
            //     label: '候選人帳號設定',
            //     link: '/accountsettings'
            // }
        ]
    },
    {
        id: 21,
        permissions: "MENU_POLLS",
        label: "投票管理",
        icon: "ri-ticket-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 22,
                permissions: "MENU_POLLS",
                label: '投票編輯',
                link: '/management'
            }
        ]
    },
    {
        id: 41,
        permissions: "MENU_BROADCAST",
        label: "推播管理",
        icon: "ri-notification-2-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 42,
                permissions: "MENU_BROADCAST",
                label: '一般推播列表',
                link: '/broadcast'
            },
            {
                id: 43,
                permissions: "MENU_BROADCAST",
                label: 'Line 推播列表',
                link: '/line-broadcast'
            }
        ]
    },
    {
        id: 23,
        permissions: "MENU_USER",
        label: "用戶/等級任務管理",
        icon: "ri-file-user-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 24,
                permissions: "MENU_USER_MANAGEMENT",
                label: '用戶查詢',
                link: '/usermanagement'
            },
            {
                id: 25,
                permissions: "MENU_USER_WONCOIN",
                label: '旺幣查詢',
                link: '/wancoin'
            },
            {
                id: 26,
                permissions: "MENU_USER_WONCOIN_TASK",
                label: '旺幣任務設定',
                link: '/wanciontask'
            },
            {
                id: 27,
                permissions: "MENU_USER_WONCOIN_EXCHANGE",
                label: '旺幣兌換設定/查詢',
                link: '/wancionexchange'
            },
            {
                id: 28,
                permissions: "MENU_USER_UPGRADE_TASK",
                label: '升等任務查詢',
                link: '/upgradetask'
            },
        ]
    },
    {
        id: 29,
        permissions: "MENU_PROGRAMS",
        label: "影音節目管理",
        icon: "ri-video-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 30,
                permissions: "MENU_PROGRAMS_HOME",
                label: '專區首頁編輯',
                link: '/homeeditor'
            },
            {
                id: 31,
                permissions: "MENU_PROGRAMS_SETTING",
                label: '節目設定',
                link: '/programsetting'
            }
        ]
    },
    // {
    //     id: 32,
    //     permissions: "MENU_CHAT_MANAGEMENT",
    //     label: "留言/聊天室管理",
    //     icon: "ri-chat-2-line",
    //     isMenuCollapsed: false,
    //     subItems: [
    //         {
    //             id: 33,
    //             permissions: "MENU_CHAT_MANAGEMENT_COMMENT",
    //             label: '留言管理',
    //             link: '/msgmanagement'
    //         },
    //         {
    //             id: 34,
    //             permissions: "MENU_CHAT_MANAGEMENT_COMMENT",
    //             label: '聊天室管理',
    //             link: '/chatroommanagement'
    //         },
    //         {
    //             id: 35,
    //             permissions: "MENU_CHAT_MANAGEMENT_BLOCK",
    //             label: '封鎖詞管理',
    //             link: '/blockwordmanagemnet'
    //         }
    //     ]
    // },
    {
        id: 36,
        permissions: "MENU_SPONSOR",
        label: "贊助管理",
        icon: "ri-hand-coin-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 37,
                permissions: "MENU_SPONSOR_ORDERS",
                label: '訂單管理',
                link: '/ordermanagement'
            }
        ]
    },
    {
        id: 38,
        permissions: "MENU_ACCOUNT_MANAGEMENT",
        label: "帳號/權限管理",
        icon: "ri-shield-user-line",
        link: '/apmanagement'
    },
    {
        id: 39,
        permissions: "MENU_CUSTOMER_SERVICE",
        label: "客服管理",
        icon: "ri-user-2-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 40,
                permissions: "MENU_CUSTOMER_SERVICE",
                label: '線上詢問列表',
                link: '/onlineinquiry'
            }
        ]
    },
    {
        id: 50,
        permissions: "MENU_CONTENT",
        label: "災害通報中心",
        icon: "fe-alert-circle",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 51,
                permissions: "MENU_CONTENT",
                label: '全國災害資訊',
                link: '/disaster-list'
            }
        ]
    }
];